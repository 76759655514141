/**
 * Created by mac on 2/3/20
 */

var Furniture = function (home, data) {
    cleverapps.EventEmitter.call(this);

    this.home = home;
    this.stage = 0;

    this.init(data);
};

Furniture.prototype = Object.create(cleverapps.EventEmitter.prototype);
Furniture.prototype.constructor = Furniture;

Furniture.prototype.removeHighlight = function () {
    if (!this.unit) {
        return;
    }

    var hightlight = this.unit.findComponent(HighLight);
    if (hightlight) {
        hightlight.removeHighlight();
    }
};

Furniture.prototype.addHighlight = function () {
    if (!this.unit) {
        return;
    }

    var hightlight = this.unit.findComponent(HighLight);
    if (hightlight) {
        hightlight.addHighlight();
    }
};

Furniture.prototype.getChoiceComponent = function () {
    if (!this.unit) {
        return;
    }

    return this.unit.findComponent(RegionControl) || this.unit.findComponent(Switchable);
};

Furniture.prototype.init = function (data) {
    Object.assign(this, data);
    this.stages.forEach(function (stage) {
        stage.title = stage.dialogue;
    });
};

Furniture.prototype.bindUnit = function (unit) {
    this.unit = unit;
    this.stage = unit.stage;
};

Furniture.prototype.readyToOpen = function () {
    var furniture = this.home.getFurniture();
    for (var i = 0; i < this.order.length; i++) {
        var dependency = this.order[i];
        if (!furniture[dependency].isComplete()) {
            return false;
        }
    }

    var ready = !this.isComplete();

    if (ready && !this.unit) {
        var code = Families.FurnitureByName[this.name].unitCode;
        var fakeUnit = Map2d.currentMap.fogs.findFakeUnit({
            code: code,
            type: "furniture",
            stage: 0
        });

        if (fakeUnit) {
            var newUnit = new Unit(fakeUnit);
            var pos = { x: fakeUnit.x, y: fakeUnit.y };

            if (!newUnit.canMoveTo(pos.x, pos.y)) {
                pos = Map2d.currentMap.findEmptySlot(pos.x, pos.y, newUnit);
            }

            newUnit.setPosition(pos.x, pos.y);
            Map2d.currentMap.onAddUnit(pos.x, pos.y, newUnit);
            Map2d.currentMap.add(Map2d.LAYER_UNITS, pos.x, pos.y, newUnit);
            Map2d.currentMap.onUnitAvailable(newUnit);
        }

        cleverapps.throwAsync("no unit " + Families.FurnitureByName[this.name].unitCode + " for furniture ready to open");
    }

    return ready;
};

Furniture.prototype.hasChoices = function () {
    return this.choices !== undefined;
};

Furniture.prototype.isCurrent = function () {
    return this.id <= this.home.getCurrentFurnitureId();
};

Furniture.prototype.amountStages = function () {
    return this.stages.length;
};

Furniture.prototype.isComplete = function () {
    if (!this.isCurrent()) {
        return false;
    }

    if (this.throwaway && !this.unit) {
        return true;
    }

    return this.stage >= this.amountStages();
};

Furniture.prototype.getInfo = function () {
    return this.stages[this.stage];
};

Furniture.prototype.canBeUpgraded = function () {
    return this.getInfo().cost <= cleverapps.meta.logic.stars;
};

Furniture.prototype.getStage = function () {
    return this.unit && this.unit.stage;
};

Furniture.prototype.nextStage = function () {
    this.stage++;

    return !this.isComplete();
};
