/**
 * Created by razial on 04.03.2025.
 */

var UnitsLibraryFromSlotAdapter = function (slot) {
    this.slot = slot;
};

UnitsLibraryFromSlotAdapter.prototype.load = function () {
    return cleverapps.dataLoader.load(DataLoader.TYPES.UNITS_LIBRARY + this.slot);
};

UnitsLibraryFromSlotAdapter.prototype.save = function (data, fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.UNITS_LIBRARY + this.slot, data);
    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("unitslibrary" + this.slot);
    }
};

var UnitsLibraryFromHomefixAdapter = function () {
    
};

UnitsLibraryFromHomefixAdapter.prototype.load = function () {
    return Homefix.currentHomefix.getInfo().unitsLibrary;
};

UnitsLibraryFromHomefixAdapter.prototype.save = function (data) {
    if (Homefix.currentHomefix) {
        Homefix.currentHomefix.saveUnitsLibrary(data);
    }
};

var UnitsLibraryHelper = {
    slots: {},

    Switch: function (locationId) {
        cleverapps.unitsLibrary = UnitsLibraryHelper.GetInstance(locationId);
    },

    GetInstance: function (locationId) {
        var slot = UnitsLibraryHelper.GetSlot(locationId);
        return UnitsLibraryHelper.GetInstanceBySlot(slot);
    },

    GetSlot: function (locationId) {
        // for backward compatibility
        if (["mergecraft", "wondermerge", "fairy"].indexOf(cleverapps.config.name) !== -1 && ["collections", "dragonia", "dragonia2", "dragonia3", "undersea", "undersea2", "rapunzel", "rapunzel2", "rapunzel3"].indexOf(locationId) !== -1) {
            locationId = "main";
        }
        if (["mergecraft"].indexOf(cleverapps.config.name) !== -1 && ["undersea3"].indexOf(locationId) !== -1) {
            locationId = "main";
        }

        var slot = locationId;

        if (cleverapps.config.type === "merge" && typeof MergeLogic !== "undefined") {
            var page = MergeLogic.findPageById(locationId);
            slot = page.slot || Meta.SLOT_MAIN;
        }

        return slot;
    },

    GetInstanceBySlot: function (slot) {
        if (!UnitsLibraryHelper.slots[slot]) {
            var adapter;

            if (cleverapps.config.type === "merge") {
                adapter = new UnitsLibraryFromSlotAdapter(slot);
            } else if (cleverapps.meta.getType() === Meta.HOMEFIX) {
                adapter = new UnitsLibraryFromHomefixAdapter();
            }

            UnitsLibraryHelper.slots[slot] = new UnitsLibrary(adapter);
        }

        return UnitsLibraryHelper.slots[slot];
    }
};

if (cleverapps.config.type === "merge") {
    CustomSyncers.registerBySlots("unitslibrary", function (slot) {
        return UnitsLibraryHelper.GetInstanceBySlot(slot).getInfo();
    }, function (slot, serverData) {
        UnitsLibraryHelper.GetInstanceBySlot(slot).updateInfo(serverData);
    });
}