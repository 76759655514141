/**
 * Created by andrey on 19.11.19.
 */

var HomefixScene = MainScene.extend({
    destructor: function () {
        this._super();

        if (Homefix.currentHomefix) {
            Homefix.currentHomefix.stop();
            Homefix.currentHomefix = undefined;
        }
    },

    onSceneLoaded: function () {
        this._super();

        this.homefix = new Homefix();

        this.homefix.on("animateNewTask", this.animateNewTask.bind(this), this);

        this.innerMap = new Map2dInnerView(this.homefix.map);
        this.mapView = this.innerMap.wrapWithScroll(this.mapPositionChanged.bind(this));
        this.addChild(this.mapView);
        this.setInitialScroll(this.homefix.map.getFocusCell() || this.homefix.map.getMapCenterCell());
        this.updateSize();

        var orangery = Map2d.currentMap.orangery;
        if (orangery && !cleverapps.gameModes.noControls && !cleverapps.config.wysiwygMode) {
            var orangeryView = new OrangeryView(orangery);
            this.addChild(orangeryView);

            cleverapps.focusManager.registerControl(orangery.getControlName(), new HidingNode(orangeryView, cleverapps.UI.VERTICAL));
        }

        this.addPlayButton();

        this.runAction(new cc.RepeatForever(new cc.Sequence(
            new cc.DelayTime(5),
            new cc.PlaySound(bundles.main.urls.city_loop_effect),
            new cc.DelayTime(5)
        )));

        this.addTasksIcon();

        cleverapps.focusManager.registerControl("finger_control", this.createListener(function (visible) {
            if (visible) {
                this.createFinger();
            }
        }.bind(this)));

        if (cleverapps.git) {
            cleverapps.git.changeFolders([GitManager.FOLDERS.UNIT_ANCHORS]);
        }
    },

    setInitialScroll: function (cell) {
        var scrollCell = this.innerMap.getCell(cell.x, cell.y);
        scrollCell = scrollCell ? this.mapView.normalizePoint(this.mapView.targetToPoint(scrollCell)) : this.mapView.getContainerPosition();

        this.mapView.scrollToPoint(scrollCell);
    },

    updateSize: function () {
        this._super();

        if (this.mapView) {
            var bgSize = cleverapps.resolution.getBgSize();
            var safePadding = cleverapps.resolution.getSafePadding();
            this.mapView.setContentSize(bgSize.width, bgSize.height);
            this.mapView.setPositionRound(bgSize.width / 2 - safePadding.left, bgSize.height / 2 - safePadding.bottom);

            this.mapView.zoomHandler.setZoom(this.mapView.zoom, true);
        }
    },

    addPlayButton: function () {
        var playButton = this.playButton = new PlayButtonView({
            isSmall: true,
            hard: true
        });
        this.addChild(playButton);

        cleverapps.focusManager.registerControl("play_button", new HidingNode(playButton, HidingNode.DIR.NONE));
    },

    addTasksIcon: function () {
        var tasksIcon = new HomeTasksIcon(this);
        this.addChild(tasksIcon);

        this.tasksIcon = new HidingNode(tasksIcon, cleverapps.UI.VERTICAL);
        cleverapps.focusManager.registerControl("hometasks_icon", this.tasksIcon);

        cleverapps.aims.registerTarget("cookie", this.tasksIcon, {
            controls: ["hometasks_icon"]
        });
    },

    createFinger: function () {
        FingerView.hintTap(this.homefix.getFurnitureToUpgrade() ? this.tasksIcon : this.playButton);
    },

    animateNewTask: function (furniture, f) {
        new NewTaskAnimation(furniture, this, f);
    },

    playIntro: function (f) {
        this.homefix.showUp(f);
    },

    mapPositionChanged: function () {
        if (!this.mapView) {
            return;
        }

        var styles = this.styling;
        if (!styles.parallax || !this.background) {
            return;
        }

        var scale = this.mapView.zoom - this.mapView.zoomHandler.minZoom;
        var safePadding = cleverapps.resolution.getSafePadding();
        var normalizedScale = Math.max(1.1 * this.height / this.background.height, 1.25 + HomefixScene.PARALLAX_FACTOR * scale) + 0.001 * (safePadding.top + safePadding.bottom);
        this.background.setScale(normalizedScale);

        var scroll = this.mapView.getCurrentScroll();
        var normalizedScroll = {
            x: 2 * (scroll.x - 0.5),
            y: 2 * (scroll.y - 0.5)
        };

        var x = this.width / 2 + HomefixScene.PARALLAX_FACTOR * this.width * normalizedScroll.x * (1 + scale);
        var y = this.height / 2 + HomefixScene.PARALLAX_FACTOR * this.height * normalizedScroll.y * (1 + scale);

        if (styles.parallax.hideHorizon) {
            x = this.width * (0.5 + 0.1 * normalizedScroll.x);
            y = this.height * (1.2 + 0.9 * normalizedScroll.y);
        }

        this.background.setPositionRound(x - safePadding.left, y - safePadding.bottom);
    },

    getBackgroundStyles: function () {
        return {
            movableAnimation: bundles.map_background.jsons.map_background_json,
            parallax: {
                hideHorizon: true
            }
        };
    },

    getPreferredBundles: function () {
        return ["main"];
    },

    cachedBundles: function () {
        return ["home_task_window", "make_choice_window"];
    },

    listBundles: function () {
        var result = this._super();

        result.push("location_" + cleverapps.meta.selectedLocationId());
        result.push(Map2d.getTilesTexture(cleverapps.meta.selectedLocationId()));
        result.push(Map2d.getUnitsTexture(cleverapps.meta.selectedLocationId()));

        return result;
    }
});

HomefixScene.PARALLAX_FACTOR = 1;

cleverapps.styles.HomefixScene = {
    mergeBonus: {
        x: 0,
        y: 30
    }
};
