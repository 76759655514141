/**
 * Created by evgenijsenkevic on 01.10.2024
 */

FogTileView.prototype.showGroundStateAnimation = cleverapps.extendFunc(FogTileView.prototype.showGroundStateAnimation, function () {
    this._super.apply(this, arguments);

    this.addParticles();
    this.addLight();
});

FogTileView.prototype.addFakeUnitNode = function (fakeUnit) {
    if (Families[fakeUnit.code].units[fakeUnit.stage].invisible) {
        return;
    }

    var unitNode = this.unitNode = new cc.Node();
    var unitImage = UnitView.getUnitImage(fakeUnit, {
        keepLazy: true,
        alignAnchorX: true,
        alignAnchorY: true
    });

    unitNode.setAnchorPoint(unitImage.anchorX, unitImage.anchorY);
    this.unitNode.addChild(unitImage);
    this.unitNode.unitImage = unitImage;
    Map2d.currentMap.getMapView().addTile(Map2d.LAYER_UNITS, this.fogTile.x, this.fogTile.y, unitNode);
};

FogTileView.prototype.removeFromParent = cleverapps.extendFunc(FogTileView.prototype.removeFromParent, function () {
    this._super.apply(this, arguments);

    if (this.light) {
        this.light.removeFromParent();
        delete this.light;
    }
});

FogTileView.prototype.onOpenFogBlock = cleverapps.extendFunc(FogTileView.prototype.onOpenFogBlock, function () {
    this._super.apply(this, arguments);
    this.addFlashLight();
});

FogTileView.prototype.addFlashLight = function () {
    var flashLight = new cleverapps.Spine(bundles.fog.jsons.fog_flashlight);
    flashLight.setAnimation(0, "animation", false);

    var map2dView = Map2d.currentMap.getMapView();
    map2dView.alignViewInGrid(this.fogTile.x, this.fogTile.y, flashLight);
    flashLight.setCompleteListenerRemove();
    map2dView.animations.addChild(flashLight);
};

FogTileView.prototype.addParticles = function () {
    var particles = new cleverapps.Spine(bundles.fog.jsons.fog_particles);
    particles.setAnimation(0, "animation", true);
    particles.setTimeScale(0.5 + Math.random());
    particles.setPosition(this.sprite.width / 2, this.sprite.height / 2);
    this.sprite.addChild(particles);
};

FogTileView.prototype.addLight = function () {
    for (var i = -1; i <= 1; i++) {
        for (var j = -1; j <= 1; j++) {
            var x = this.fogTile.x + i;
            var y = this.fogTile.y + j;

            var fog = Map2d.currentMap.getFog(x, y);

            if (!fog || this.fogTile.fogBlock.id !== fog.fogBlock.id) {
                return;
            }
        }
    }

    if (this.fogTile.x % 3 !== 0 || this.fogTile.y % 2 !== 0) {
        return;
    }

    var light = this.light = new cleverapps.Spine(bundles.fog.jsons.fog_light_ray);

    light.setAnimation(0, "animation", true);
    light.setTimeScale(0.5 + Math.random());

    var map2dView = Map2d.currentMap.getMapView();
    map2dView.alignViewInGrid(this.fogTile.x, this.fogTile.y, light);
    map2dView.animations.addChild(light);
};