/**
 * Created by mac on 2/3/20
 */

var HomefixLocation = function (locationId) {
    BaseLocation.call(this, locationId);

    this.slot = Meta.SLOT_MAIN;
};

HomefixLocation.prototype = Object.create(BaseLocation.prototype);
HomefixLocation.prototype.constructor = HomefixLocation;

HomefixLocation.prototype.complete = function () {
    this.completed = true;
};

HomefixLocation.prototype.isCompleted = function () {
    return this.completed;
};

HomefixLocation.prototype.amountLevelStars = function (level) {
    return level.isHard() ? 3 : 1;
};

HomefixLocation.prototype.getProgress = function () {
    return Homefix.currentHomefix.getProgress();
};

HomefixLocation.GetMainScene = function () {
    return HomefixScene;
};
