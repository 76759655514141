/**
 * Created by iamso on 24.03.2020.
 */

var HomeTasksIcon = cc.Node.extend({
    ctor: function () {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(cleverapps.styles.HomeTasksIcon);

        this.setupChildren();

        this.addAnimation();
        this.setAnimation();

        cleverapps.UI.applyHover(this);
        cleverapps.UI.onClick(this, this.onPress.bind(this));
    },

    setupChildren: function () {
        this.setPositionRound(cleverapps.styles.HomeTasksIcon.position);
    },

    addAnimation: function () {
        var animation = this.animation = new cleverapps.Spine(bundles.window_icons.jsons.hometask_icon_json);
        this.addChild(animation);
        animation.setPositionRound(this.width / 2, this.height / 2);
    },

    addNewTaskAnimation: function () {
        this.animation.setAnimationAndIdleAfter("book_newtask", "book_on");
    },

    setAnimation: function () {
        var name = Homefix.currentHomefix && Homefix.currentHomefix.getFurnitureToUpgrade() ? "book_on" : "book_idle";
        this.animation.setAnimation(0, name, true);
    },

    onPress: function () {
        this.animation.setAnimation(0, "book_off", false);
        this.animation.setCompleteListener(function () {
            this.animation.setCompleteListener();
            this.setAnimation();
        }.bind(this));

        cleverapps.focusManager.display({
            focus: "HomeTasksWindow",
            action: function (f) {
                new HomeTasksWindow();
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        });
    }
});

cleverapps.styles.HomeTasksIcon = {
    width: 165,
    height: 185,
    position: [
        {
            x: { align: "left", dx: 20 },
            y: { align: "bottom", dy: 120 }
        },
        {
            x: { align: "left", dx: 50 },
            y: { align: "bottom", dy: 15 }
        },
        {
            x: { align: "left", dx: 50 },
            y: { align: "bottom", dy: 15 }
        }
    ]
};
